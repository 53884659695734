import React, { Suspense } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import "react-quill/dist/quill.snow.css";
import "./scss/main.scss";
import configureStoreProd from "./Pages/config/configureStore.prod";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import { lazy } from "react";
import { GoogleOAuthProvider } from '@react-oauth/google';
const { persistor, store } = configureStoreProd();

function App() {

  const routes = [
    // Auth
    { url: "/login", path: "Login" },
    { url: "/signup", path: "Signup" },
    { url: "/", path: "Home" },
    { url: "*", path: "NotFoundPage" },
    { url: "/profile", path: "Profile" },
    { url: "/profile/:tab", path: "Settings" },
    { url: "/forgotpassword", path: "Forgotpassword" },
    { url: "/resetpassword", path: "Resetpassword" },
    { url: "/", element: <Navigate to="/" /> },

    // Blogs
    { url: "/blogs", path: "Blogs" },
    { url: "/blog-detail/:id", path: "Blogs/View" },

    // Contact Us
    { url: "/contactus", path: "Contactus" },

    // About Us
    { url: "/about-us", path: "Aboutus" },

    // Privacy-Policy
    { url: "/privacy-policy", path: "Privacy" },

    // Terms And Condition
    { url: "/terms-condition", path: "Terms" },

    // Business List
    { url: "/business", path: "BusinessList" },
    { url: "/business/detail/:id?", path: "BusinessList/View" },

    // Voter List
    { url: "/voters", path: "VoterList" },
    { url: "/voter/detail/:id?", path: "VoterList/View" },

    { url: "/super/vote", path: "SuperVote" },
    { url: "/dashboard", path: "Dashboard" },
    { url: "/voted/business", path: "VotedBusiness" },

    // Claim Business
    { url: "business/list", path: "ClaimBusiness" },
    { url: "business/edit/:id", path: "ClaimBusiness/AddEdit" },
    { url: "business/add", path: "ClaimBusiness/AddEdit" },
    { url: "business/details/:id", path: "ClaimBusiness/View" },

  ];

  sessionStorage.clear();


  return (
    <>
      <Provider store={store}>
        <PersistGate loading={"loading ..."} persistor={persistor}>
          <Suspense
            fallback={
              <div id="loader" className="loaderDiv">
                <div>
                  <img
                    src="/assets/img/loader.gif"
                    alt="logo"
                    className="loaderlogo"
                  />
                </div>
              </div>
            }
          >
            <GoogleOAuthProvider clientId="388674765113-81s7h8okb0s2i7qje4cruvlftckhivvp.apps.googleusercontent.com">
              <Router>
                <Routes>
                  {routes.map((itm) => {
                    const Element = lazy(() => import(`./Pages/${itm.path}`));
                    return (
                      <Route
                        path={itm.url}
                        element={itm.path ? <Element /> : itm.element}
                      />
                    );
                  })}
                </Routes>
              </Router>
            </GoogleOAuthProvider>
          </Suspense>
        </PersistGate>
      </Provider>
      <div id="loader" className="loaderDiv d-none">
        <div>
          <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
}

export default App;
